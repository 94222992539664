<template>
  <div class="articleManage">
    <div class="page-title">文章咨询</div>
    <div class="row-me row-center flex-wrap mT20">
      <div>内容标题</div>
      <div class="w200 mL10">
        <el-input placeholder="请输入内容标题" style="width: 100%;" clearable v-model="name"></el-input>
      </div>
      <div class="mL20">发布时间</div>
      <div class="w400 mL10">
        <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        end-placeholder="结束时间" style="width: 100%;" clearable v-model="date">
        </el-date-picker>
      </div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
    </div>
    <div class="row-me row-center mT20">
      <div class="btn-blue w100 btn-height40" @click="clickAdd">新建</div>
    </div>
    <div class="column-me mT20">
      <div>
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style">
          <el-table-column type="index" label="序号" width="80" align="center"/>
          <el-table-column prop="id" label="ID" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="title" label="标题" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subtitle" label="副标题" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="封面图" align="center" show-overflow-tooltip>
            <template #default="scope">
              <el-image :src="scope.row.cover" :preview-src-list="[scope.row.cover]" fit="cover" class="img50"
                        hide-on-click-modal>
                <template #error>
                  <img src="../../assets/img/error.png" class="wh100Per objFitCover">
                </template>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="issueTime" label="发布时间" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="150" fixed="right">
            <template #default="scope">
              <div class="row-me center-all">
                <div class="blue-text mR20" @click="clickEdit(scope.row)">编辑</div>
                <el-popconfirm title="您确认删除吗？" width="250" @confirm="clickDelete(scope.row)">
                  <template #reference>
                    <div class="red-text">删除</div>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <!--新建-->
    <el-dialog title="新建" v-model="visible" width="800px" top="2vh" :destroy-on-close="true">
      <div>
        <div class="row-me row-center">
          <div class="left-view"><span class="red-star">*</span>标题：</div>
          <div class="flex1">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.title"
                      maxlength="50"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="left-view">副标题：</div>
          <div class="flex1">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.subtitle"
                      maxlength="50"></el-input>
          </div>
        </div>
        <div class="row-me mT20">
          <div class="left-view"><span class="red-star">*</span>上传封面图：</div>
          <div class="flex1">
            <el-upload class="img100" action="" :show-file-list="false" :before-upload="beforeAvatarUpload"
                       accept=".jpg,.jpeg,.png" :auto-upload="true" :http-request="uploadFile">
              <img :src="detailItem.cover" v-if="!this.$method.isEmpty(detailItem.cover)"
                   class="wh100Per objScaleDown"/>
              <div class="column-me row-center" v-else>
                <img src="../../assets/img/add.png" class="img35"/>
                <div class="font14 colorGrey mT10">上传封面图</div>
              </div>
            </el-upload>
            <div class="font12 colorRed mT10">支持.png .jpg格式，大小不超过5M，仅上传一张</div>
          </div>
        </div>
        <div class="row-me mT20">
          <div class="left-view"><span class="red-star">*</span>内容详情：</div>
          <div class="flex1">
            <div id="websiteEditorElem"></div>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="left-view">虚拟浏览量：</div>
          <div class="flex1">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.viewNumFake"
                      @input="detailItem.viewNumFake=$method.checkInput(detailItem.viewNumFake,0)"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="left-view">发布时间：</div>
          <div class="flex1">
            <el-date-picker type="datetime" placeholder="请选择" style="width: 100%;" :clearable="false"
                            v-model="detailItem.issueTime">
            </el-date-picker>
          </div>
        </div>
        <div class="w100Per row-me row-center flex-end1 mT50">
          <div class="btn-blank w100 btn-height40 mR20" @click="visible=false">取消</div>
          <div class="btn-blue w100 btn-height40" @click="clickOk">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import E from 'wangeditor'

export default {
  components: {},
  data() {
    return {
      name: '',
      date: [],
      tableData: [],
      pageNo: 1,
      total: 0,
      detailItem: {},
      visible: false,
      editor: null,
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.clickSearch()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.name = ''
      this.date = []
      this.clickSearch()
    },
    clickAdd() {
      this.visible = true
      this.detailItem = {
        issueTime: this.$method.getNowTime()
      }
      this.$nextTick(() => {
        this.initEditor()
      })
    },
    clickEdit(item) {
      this.detailItem = this.$method.deepClone(item)
      this.visible = true
      this.$nextTick(() => {
        this.initEditor()
      })
    },
    clickOk() {
      if (this.$method.isEmpty(this.detailItem.title)) {
        this.$message.error("请输入标题")
        return
      }
      if (this.$method.isEmpty(this.detailItem.cover)) {
        this.$message.error("请上传封面图")
        return
      }
      let content = this.editor.txt.html()
      if (this.$method.isEmpty(content)) {
        this.$message.error("内容详情不能为空")
        return
      }
      this.detailItem.content = content
      this.detailItem.type = 2
      this.$postForm('/articles/articleSave', this.detailItem).then((res) => {
        if (res) {
          this.$message.success('保存成功')
          this.visible = false
          if (this.detailItem.id) {
            this.getData()
          } else {
            this.clickSearch()
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //删除
    clickDelete(item) {
      this.$postForm('/articles/articleDeleted', {
        ids: [item.id]
      }).then((res) => {
        if (res) {
          this.$message.success('删除成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //上传图片验证
    beforeAvatarUpload(file) {
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('上传图片只支持JPG、JPEG、PNG格式!')
        return false
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片不能超过5M')
        return false
      }
      return true
    },
    //上传
    uploadFile(param) {
      this.$uploadFileAli(param.file, res => {
        this.detailItem.cover = res
      }, err => {
        console.log(err)
      })
    },
    //初始化富文本
    initEditor() {
      //文档：https://www.bookstack.cn/read/wangeditor-4.7-zh/8421901ee781fd3d.md
      //获取内容的方法：this.editor.txt.text()
      this.$nextTick(() => {
        // wangeditor
        this.editor = new E('#websiteEditorElem')
        this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
          for (let file of resultFiles) {
            this.$uploadFileAli(file, res => {
              //调接口上传后，返回视频链接给insertVideoFn
              insertImgFn(res)
            }, err => {
              console.log(err)
            })
          }
        }
        this.editor.config.customUploadVideo = (resultFiles, insertVideoFn) => {
          for (let file of resultFiles) {
            this.$uploadFileAli(file, res => {
              //调接口上传后，返回视频链接给insertVideoFn
              insertVideoFn(res)
            }, err => {
              console.log(err)
            })
          }
        }
        // 隐藏网络图片
        this.editor.config.showLinkImg = false
        this.editor.config.showLinkVideo = false
        // 创建一个富文本编辑器
        this.editor.create()
        // 初始化富文本内容
        if (this.detailItem.id) {
          this.editor.txt.html(this.detailItem.content)
        }
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    getData() {
      this.$postForm('/articles/articlePage', {
        name: this.name,
        startTime: this.date.length === 2 ? this.date[0] : '',
        endTime: this.date.length === 2 ? this.date[1] : '',
        type: 2,
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.articleManage {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .left-view {
    width: 120px;
    text-align: right;
    margin-right: 20px;
  }
}
</style>
